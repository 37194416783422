@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Override variables before import
$theme-colors: (
 "primary": #1B1464,
 "secondary": #504F64,
 "info": #0D0A2E,
 "link": #E9E7FA,
 "warning": #F6F6F6,
 "danger": #F25A25,
 "success": #D3D2E1,
 "dark": #BFBED0,
);

$border-radius: 0px;
$border-radius-lg: 15px;
$border-radius-sm: 24px;
$font-family-sans-serif: "Poppins", sans-serif;

@import '~bootstrap/scss/bootstrap.scss'
